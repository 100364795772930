<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 16 16"
  >
    <path
      fill="currentColor"
      d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0Zm0 14.5a6.5 6.5 0 1 1 .01-13.01A6.5 6.5 0 0 1 8 14.5Zm1.25-4h-.5V7.75A.75.75 0 0 0 8 7H7a.75.75 0 0 0-.75.75c0 .41.34.75.75.75h.25v2h-.5a.75.75 0 0 0-.75.75c0 .41.34.75.75.75h2.5a.75.75 0 0 0 0-1.5ZM8 6a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"
    />
  </svg>
</template>
